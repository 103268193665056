import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Accent from "../../../../../components/accent"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Hyphen from "../../../../../components/hyphen"
import Poem from "../../../../../components/poem"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Text from "../../../../../components/text"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/versmass-bestimmen/aufgabe" />
      }
    >
      <Seo title="Versmaß bestimmen" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Versmaß bestimmen
            </Heading>
          </Stack>
          <Paragraph>
            Um seinen Gedichten einen ganz bestimmten Sound zu verliehen, hat
            Hölderlin aber auch mit komplexen griechischen Versmaßen
            experimentiert. Werfen wir also einen Blick auf die Betonungsfolgen
            in ›Hälfte des Lebens‹.
          </Paragraph>
          <Paragraph>
            Um das Versmaß eines Gedichts zu bestimmen, zerlegt man die Wörter
            zunächst in ihre Silben. Zum Beispiel so:
          </Paragraph>
          <Box sx={{ bg: "muted", p: 6, borderRadius: "lg", height: "100%" }}>
            <Paragraph>
              Mit gel
              <Hyphen color="primary" />
              ben Bir
              <Hyphen color="primary" />
              nen hän
              <Hyphen color="primary" />
              get
            </Paragraph>
          </Box>
          <Paragraph>
            Anschließend muss man herausfinden, welche Silbe beim Sprechen
            betont wird – und welche nicht. Die betonten Silben erkennst du
            daran, dass du sie lauter und hervorgehobener aussprichst als die
            unbetonten. Im Zweifelsfall kannst du auch die Gegenprobe machen und
            ausprobieren, wie das Wort klingen würde, wenn andere Silben betont
            werden.
          </Paragraph>
          <Box sx={{ bg: "muted", p: 6, borderRadius: "lg", height: "100%" }}>
            <Stack space={1}>
              <Poem>
                <p>
                  Mit <Accent>gel</Accent>
                  <Hyphen />
                  ben <Accent>Bir</Accent>
                  <Hyphen />
                  nen <Accent>hän</Accent>
                  <Hyphen />
                  get
                </p>
              </Poem>
              <Text sans size={8} color="primary">
                ◡ — ◡ — ◡ — ◡
              </Text>
              <Text color="whisper" sans>
                unbetont, betont, unbetont,betont, unbetont, betont, unbetont
              </Text>
            </Stack>
          </Box>
          <Paragraph>
            Die betonten Silben werden mit einem Strich gekennzeichnet —, die
            unbetonten erhalten einen halboffenen Bogen ◡. Aus dem Muster, das
            sich daraus ergibt, kann das Versmaß bestimmt werden.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
